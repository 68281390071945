import { createMachine, assign, send } from 'xstate';
import { Geolocation, Geoposition } from "@ionic-native/geolocation";
import { base64FromPath } from '@capacitor-community/react-hooks/filesystem';

//import { Geolocation } from '@capacitor/geolocation'; THIS version of the plugin provides the checkPermissions method.
// sadly, it appears to only be possible to call it from Native builds, not pwa builds.
async function checkLocationStatus(context) {
  try {
    const geostatus_obj = await Geolocation.checkPermissions();
    const geostatus_str = geostatus_obj.location;
    console.log(" # # # Geolocation Status found as: " + geostatus_str);
    return geostatus_str;
  } catch (err) {
    console.log("Failed Geolocation Status probe with Error: ");
    console.log(err);
    return err;
  }

}

async function getLocation(context) {
  console.log('activating GPS...');
  try {
    //return Geolocation.getCurrentPosition().then((georesp)=>georesp);
    const geoposition = await Geolocation.getCurrentPosition();
    console.log(geoposition);

    return {
      accuracy: geoposition.coords.accuracy,
      altitude: geoposition.coords.altitude,
      altitudeAccuracy: geoposition.coords.altitudeAccuracy,
      heading: geoposition.coords.heading,
      latitude: geoposition.coords.latitude,
      longitude: geoposition.coords.longitude,
      speed: geoposition.coords.speed,
      timestamp: geoposition.timestamp
    };
  } catch (e) {
    window.alert("Location Data is Unavailable - Please turn on and grant all Permissions to this App");
    console.log("FAILED GPS - NO POSITION AVAILABLE");
    console.log(e);
    context.geoerror = e;
    return false;
  }
}

async function getEmployees(context) {
  let employees_acc = [];
  let outcome_promise = await window.db.collection("employees")
    .where("status", "==", "Active")
    .get()
    .then(
      function (querySnapshot) {
        console.log("...async fetch employees running...");
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          let refObj = doc.data();
          refObj.fid = doc.id;
          console.log(doc.id, " => ", doc.data());
          employees_acc.push(refObj);
        });
      });

  context.employees = employees_acc;
  console.log("...fetched...", employees_acc);
  return outcome_promise;
}

async function getTasks(context) {
  let tasks_acc = [];
  let outcome_promise = await window.db.collection("tasks")
    .get()
    .then(
      function (querySnapshot) {
        console.log("...async fetch tasks running...");
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          let refObj = doc.data();
          refObj.fid = doc.id;
          console.log(doc.id, " => ", doc.data());
          tasks_acc.push(refObj);
        });
      });

  context.tasks = tasks_acc;
  console.log("...fetched...", tasks_acc);
  return outcome_promise;
}

function sleeptest(ms) {
  const start = performance.now();
  while (performance.now() - start < ms);
}

async function addPunchDeprec(context, event) {
  let picupload_promise_outcome = Promise.resolve("done"); //resolved when not campunching going into upload pic branch next
  if (typeof event.storagepath !== "undefined") { //not undefined
    if (!!event.storagepath) {                    //not empty string
      console.log("UPLOADING PIC TO CLOUD NOW");
      // Create a reference to 'images/ProjectLocale_ProjectFID/EmployeeFID_1615xxxTS.jpg'
      let storageRef = window.fbstorage.ref();
      var newPicRef = storageRef.child(event.storagepath);
      // 'file' comes from the Blob or File API
      var metadata = {
        contentType: 'image/jpeg',
      };
      //const base64Data = await base64FromPath(event.picblob.webPath);  
      //console.log(base64Data);
      //picupload_promise_outcome = newPicRef.putString(base64Data, 'base64url', metadata);
      //picupload_promise_outcome = newPicRef.put(event.picblob, metadata);

      const local_pic_response = await fetch(event.picblob.webPath);
      //const true_pic_blob = await local_pic_response.blob({type : 'image/jpeg'});
      const true_pic_blob = Blob.build(local_pic_response, { type: 'image/jpeg' });
      console.log(true_pic_blob);
      picupload_promise_outcome = newPicRef.put(true_pic_blob, metadata);
      console.log("Put new  Pic in storage??", picupload_promise_outcome);
      //  .then((snapshot) => {
      //  console.log('Uploaded a blob or file!');   });
    }
  }

  let punch_obj = {
    first_name: context.latest_punched_employee.first_name,
    last_name: context.latest_punched_employee.last_name,
    timestamp: context.latest_punched_employee.timestamp,
    project_name: context.project.name,
    project_id: context.project.fid,
    note: "TODO...1",
    pic_id: "TODO...2",
    pic_url: "TODO...3",
    user_name: "TODO ... 0",
    user_id: "TODO -1",
    location_latitude: context.geodata.latitude,
    location_longitude: context.geodata.longitude,
    location_timestamp: context.geodata.timestamp,
    //location_meta: {accuracy: context.geodata.accuracy, altitude: context.geodata.altitude, altitudeAccuracy: context.geodata.altitudeAccuracy, heading: context.geodata.heading, speed: context.geodata.speed},
  };

  let db_promise_outcome = await window.db.collection("punches").add(punch_obj);
  console.log("Added new  Punch document ??", db_promise_outcome);

  let sync_net_promises = [db_promise_outcome, picupload_promise_outcome];
  let sync_net_promise = Promise.all(sync_net_promises);


  return sync_net_promise;
}

const addPunch = async (context, event) => {
  console.log("addPunch executed with context and event as follows: ");
  console.log(context);
  console.log(event);

  let punch_obj = {
    first_name: context.latest_punched_employee.first_name,
    last_name: context.latest_punched_employee.last_name,
    employee_num: context.latest_punched_employee.employee_num,
    employee_fid: context.latest_punched_employee.fid,
    timestamp: context.latest_punched_employee.timestamp,
    project_name: context.project.name,
    project_id: context.project.fid,
    note: event.data.punchnote,//context.punchNote,//context.latest_punched_employee.punchNote,
    pic_id: "TODO...2",
    pic_url: "TODO...3",
    user_name: context.appUser.email,
    user_id: context.appUser.uid,
    location_latitude: context.geodata.latitude,
    location_longitude: context.geodata.longitude,
    location_timestamp: context.geodata.timestamp,
    task_code: event.data.taskcode
    //location_meta: {accuracy: context.geodata.accuracy, altitude: context.geodata.altitude, altitudeAccuracy: context.geodata.altitudeAccuracy, heading: context.geodata.heading, speed: context.geodata.speed},
  };

  let picupload_promise_outcome = Promise.resolve("done"); //resolved when not campunching going into upload pic branch next

  console.log("New chainey addPunch");
  let mime = 'application/octet-stream';
  const imgmime = 'image/jpeg';
  let uri = event.picblob.webPath;



  const resolver = (piclink) => {
    console.log("Yep, doing the actual Time Punch now, with link to: ", piclink);
  }

  const rejecter = (errobj) => {
    console.log("Nope, there was an error in the upload image chain, see: ", errobj);
  }

  //const local_pic_response = await fetch(event.picblob.webviewPath);
  //console.log("local fetch picblob response: ", local_pic_response);

  console.log("Defining Promse now");
  picupload_promise_outcome = new Promise((resolve, reject) => {
    //const uploadUri = Platform.OS === 'ios' ? uri.replace('file://', '') : uri;
    const sessionID = Date.now();
    let uploadBlob = null;
    let storageRef = window.fbstorage.ref();
    let newPicRef = storageRef.child(event.storagepath);

    //fs.readFile(uri, 'base64')
    console.log("picblob: ", event.picblob);
    const base64Data = base64FromPath(event.picblob.webviewPath);
    console.log("base64Data output from base64FromPath:", base64Data);

    base64FromPath(event.picblob.webviewPath)
      .then((data) => {
        console.log("base64FromPath output inside Promise chain: ", data);
        let blobstr64_parts = data.split(",");
        let blobstr64 = blobstr64_parts[1];
        return b64toBlob(blobstr64, imgmime);
        //return uploadBlob = b64toBlob(base64Data, imgmime);
        //return Blob.build(data, { type: `${mime};BASE64`});
        //return new Blob([data], { type: `${mime};BASE64`})
        //return newPicRef.putFile(event.picblob.webPath);
      })
      .then((blob) => {
        //  uploadBlob = blob;
        console.log("blobstrB64 chain input: ", blob);
        //return newPicRef.putString(blobstrB64, 'base64', {contentType: imgmime});
        return newPicRef.put(blob, { contentType: imgmime });
      })
      .then(() => {
        //uploadBlob.close();
        return newPicRef.getDownloadURL();
      })
      .then((pic_url) => {
        console.log("now gotDownloadURL: ", pic_url);
        punch_obj.pic_url = pic_url;
        console.log("now set field in punch_obj: ", punch_obj);
        let db_promise_outcome = window.db.collection("punches").add(punch_obj);
        console.log("Added new  Punch document ??", db_promise_outcome);
        resolve(pic_url);
        return db_promise_outcome;
      })
      .catch((error) => {
        window.alert(error);
        console.log("There was an error in the full Pic upload plus Time Punch chain.", error);
        reject(error);
      });
  });

  //let db_promise_outcome = await window.db.collection("punches").add(punch_obj);
  //console.log("Added new  Punch document ??", db_promise_outcome);
  //let sync_net_promises = [db_promise_outcome, picupload_promise_outcome];
  //let sync_net_promise = Promise.all(sync_net_promises);
  //return sync_net_promise;
  /*** 
   *   NOTE: Invoke command seems to ALWAYS return an Event with type CANCEL
   *   BUT, whatever we return here winds up as contents of .data inside the resulting Event
   *   so we will piggyback the required project info in this object and the consumer of event.data.project will still be able to read it.
   */
  const okoutcome = { type: 'FULFILLED', project: context.project };
  const badoutcome = { type: 'REJECTED', project: context.project };  //todo, unmanaged event exclamation mark

  //console.log("Picupload Promise Outcome: ");
  //console.log(picupload_promise_outcome);

  return Promise.allSettled([picupload_promise_outcome]).
    then((results) => { let final_result = results[0].status == "fulfilled" ? okoutcome : badoutcome; return final_result });

  //return picupload_promise_outcome;
}


const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}


export const tkoMachine = createMachine({
  id: 'tko',
  initial: 'preflight',
  context: {
    geodata: null
  },
  states: {
  preflight: {
      invoke: {
        id: 'checkLocationStatus',
        src: (context, event) => checkLocationStatus(context),
        onDone: {
          target: 'idle',
          actions: assign({ permissionstatus: (context, event) => event.data })
        },
        onError: {
          target: 'idle',
          actions: assign({ permissionstatus: (context, event) => event.data })
        }
      },
    },
    idle: {
      on: {
        LOGIN: 'login',
        REJECT: 'login'
      }
    },
    login: {
      on: {
        RESOLVE: { target: 'geotag' },
        REJECT: 'login'
      }
    },
    geotag: {
      invoke: {
        id: 'getLocation',
        src: (context, event) => getLocation(context),
        onDone: {
          target: 'select_project',
          actions: assign({ geodata: (context, event) => event.data })
        },
        onError: {
          target: 'logout',
          actions: assign({ geoerror: (context, event) => event.data })
        }
      },
      entry: (context, event) => {
        context.busy = true;
      },
      exit: (context) => { context.busy = false }, //document.getElementById('spinner').style.display = 'none'
      on: {
        LOGGINOUT: 'logout',
        KICKOUT: 'logout'
      }
    },
    select_project: {
      invoke: {
        id: 'getEmployees',
        src: (context, event) => getEmployees(context),
        onDone: {
          actions: assign({ employees_assigned: (context, event) => event.data })
        },
        onError: {
          actions: assign({ error: (context, event) => event.data })
        }
      },
      entry: (context) => document.getElementById('spinner').style.display = 'none',
      on: {
        SELECTED: 'list_employees',
        LOGOUT: 'logout',
        KICKOUT: 'logout'
      },
    },
    list_employees: {
      entry: (context, event) => {
        setTimeout(function () {
          if (document.getElementById('hardcoded_toast_confirm')) {
            document.getElementById('hardcoded_toast_confirm').style.display = 'none';
          }
        }, 3000);
        if (event.type == 'CANCEL') {
          console.log("Confirm is Cancelled, back at punch_employee State.");
          //see Home component main State Switch side-effect definition for latest_employee cleanup on the client side.
        } else if (event.type == 'RELOAD') {
          getEmployees(context);
        } else if (event.data.type == 'REJECTED') {
          //Something went wrong with punch upload, notify user now. Cancel green okconfirm.
          console.log("<> <> <> Error with punch upload, sadface. <> <> <>");
        } else {
          context.project = event.data.project;
          console.log("Entry action into list_employees ran now. Employees already got. context.project is now: ", context.project);
          console.log("Even while event is: ", event);
        }
      },
      on: {
        RELOAD: 'list_employees',
        ONEPUNCH: 'punch_employee',
        CAMPUNCH: 'take_pic',
        LOGOUT: 'logout'
      }

    },
    punch_employee: {
      /***
      invoke: {
        id: 'getTasks',
        src: (context, event) => getTasks(context),
        onDone: {
          actions: assign({ tasks_assigned: (context, event) => event.data })
        },
        onError: {
          actions: assign({ error: (context, event) => event.data })
        }
      },
      ***/
      entry: (context, event) => {
        if (event.type == 'CANCEL') {
          console.log("Confirm is Cancelled, back at punch_employee State. We should delete the prospective latest_employee");
        } else {
          //... keep context.latest_punched_employee obj from take_pic state definition, avoid undefined error coming from a CAMDONE
          let punching_employee = typeof (event.employee) == "undefined" ? context.latest_punched_employee : event.employee;
          const punch_ts = Date.now(); //@TODO deal with TZ, Locale and all that jazz
          punching_employee.timestamp = punch_ts;
          context.latest_punched_employee = punching_employee;
          console.log("Entry action into punch_employee ran now. Employee already got.");
        }
      },
      on: {
        PUNCH: 'punch_confirm',
        LOGOUT: 'logout'
      }
    },
    take_pic: {
      entry: (context, event) => {
        //note: same entry action as punch_employee to avoid undefined event.employee issue... consider some DRY solution instead
        let punching_employee = event.employee;
        const punch_ts = Date.now(); //@TODO deal with TZ, Locale and all that jazz
        punching_employee.timestamp = punch_ts;
        context.latest_punched_employee = punching_employee;
      },
      on: {
        CAMDONE: 'punch_employee',
        CAMERROR: 'list_employees',
        LOGOUT: 'logout'
      }
    },
    punch_confirm: {
      entry: (context, event) => {
        context.latest_punched_employee.timestamp = Date.now();
        context.appUser = event.data.appuser;
        context.punchNote = event.data.punchnote;
        context.taskcode = event.data.taskcode;
        console.log("Entry action into punch_confirm executed! with EVENT DATA: ", event.data);
      },
      on: {
        CONFIRM: 'firebase_async',
        ADDPIC: 'take_pic',
        CANCEL: 'list_employees',
        LOGOUT: 'logout'
        //ADDTXT: 'take_note'
      }
    },
    firebase_async: {
      invoke: {
        id: 'addPunch',
        src: addPunch,
        onDone: {
          target: 'list_employees',
          actions: assign({ punched_today: (context, event) => context.latest_punched_employee.fid })
        },
        onError: {
          target: 'list_employees',
          actions: (a, b) => { console.log("onError with a and b: ", a) }//assign({ error: (context, event) => event.data })
        }
      },
      entry: (context, event) => {
        context.busy = true;
        context.punchNote = event.data.punchnote;
        console.log("Entry action into firebase_async executed!");
      },
      exit: (context, event) => {
        console.log("Exit firebase_async with Event: ");
        console.log(event);
        context.busy = false;
        if (typeof event.data != "undefined" && event.data.type == 'REJECTED') {
          var pretty_event_data = JSON.stringify(event, null, 2);
          window.alert("There was a sync error for this Punch, sorry. EVENT: " + pretty_event_data);
        }
        document.getElementById('hardcoded_toast_confirm').style.display = 'block';
      },
      on: {
        SYNCED: 'list_employees'
      }
    },
    logout: {
      entry: (context) => {
        context = null;
      },
      on: {
        LOGIN: 'login'
      }
    }
  }
}
);
