
import { useService } from '@xstate/react';
import {
    IonItem,
    IonLabel,
    IonNote,
    IonIcon,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBadge,
    IonChip,
    IonRippleEffect,
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/react';
import { personCircle, camera } from 'ionicons/icons';

const EmployeeListItem = ({employee, tkoService, modalShower, capturePhoto}) => {
    
    const [tkoState, sendState] = useService(tkoService);

    const punch = (ev) => {
        console.log("Punching for employee: ", employee);
        sendState({type: 'ONEPUNCH', employee: employee});
        modalShower(true);
    };

    const campunch = (ev) => {
        console.log("CAMPunching for employee: ", employee);
        sendState({type: 'CAMPUNCH', employee: employee});
        capturePhoto();
        modalShower(true);
    };


    return (
        <IonItem>
            <IonGrid>
                <IonRow>
                    <IonCol size="2">
                        <div><IonIcon icon={personCircle} color="primary"></IonIcon>
</div>
                    </IonCol>
                    <IonCol size="6">
                        <div>{employee.first_name + " " + employee.last_name}</div>
                    </IonCol>
                    <IonCol size="4">
                        <div>
                        <IonButton onClick={campunch} color="tertiary" fill="solid" strong="true">
                            <IonIcon slot="start" icon={camera} />
                            Punch
                        </IonButton>        
                        </div>
                    </IonCol>                                        
                </IonRow>
            </IonGrid>
        </IonItem>
    );
}

export default EmployeeListItem;