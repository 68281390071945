import { useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonBadge,
  IonContent,
  IonHeader,
  IonTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { useParams } from 'react-router';
import './ViewMessage.css';

import { useService } from '@xstate/react';


function EmployeeList() {
  let { id } = useParams();
  let message = {name: "Project Name here", status: "Project Status Here", locale: "Project Locale here"}

  useIonViewWillEnter(() => {
   console.log("ViewWillEnter EmployeeList for Selected Project");
  });

  return (
    <IonPage id="view-message-page">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Projects" defaultHref="/home"></IonBackButton>
          </IonButtons>
          <IonTitle>{message ? (message.name + " [" + message.locale +"]") : (<div>Project Not Found</div>)}</IonTitle>
          <IonButtons slot="end">
            <IonBadge color="medium">{message ? (message.status) : ("Unknown")}</IonBadge>
          </IonButtons>
        </IonToolbar>      
      </IonHeader>

      <IonContent fullscreen>
        {message ? (
          <>
            <IonItem>
              <IonIcon icon={personCircle} color="primary"></IonIcon>
              <IonLabel className="ion-text-wrap">
                <h2>
                  {message.name}
                  <span className="date">
                    <IonNote>{message.status}</IonNote>
                  </span>
                </h2>
                <h3>
                  Locale: <IonNote>{message.locale}</IonNote>
                </h3>
              </IonLabel>
            </IonItem>

            <div className="ion-padding">
              <h1>Punch List</h1>
              <ul>
                  <li>item 1</li>
                  <li>item 2</li>
                  <li>item 3</li>
                  <li>item 4</li>
                  <li>item 5</li>
              </ul>
            </div>
          </>
        ) : (
          <div>Project not found</div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default EmployeeList;
