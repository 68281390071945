import ProjectListItem from '../components/ProjectListItem';
import EmployeeListItem from '../components/EmployeeListItem';

import { useState, useEffect, useReducer } from 'react';
import { Message, getMessages } from '../data/messages';
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonChip,
  IonIcon,
  IonLabel,
  IonProgressBar,
  IonItem,
  IonActionSheet,
  IonPopover,
  IonCard,
  IonButton,
  IonCardContent,
  IonModal,
  IonListHeader,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonRow,
  IonCol,
  IonTextarea,
  IonToast,
  IonImg,
  IonFooter,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonMenuButton
} from '@ionic/react';
import './Home.css';

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth"; //require('firebase/auth');
import * as firebaseui from 'firebaseui';
import 'firebase/storage';

import { Geolocation, Geoposition } from "@ionic-native/geolocation";
import { paperPlane, sendSharp, keyOutline, cloudUploadOutline } from 'ionicons/icons';
import { trash, share, caretForwardCircle, heart, close, settings, logoVimeo, add, documentTextOutline, cameraOutline, star, create, personCircleOutline } from 'ionicons/icons';

import { usePhotoGallery } from '../hooks/usePhotoGallery';

import { useMachine } from '@xstate/react';
import { tkoMachine } from '../data/tkoMachine';

//import {inspect} from '@xstate/inspect';
//inspect({iframe: false});

// SUPERGLOBAL
window.appUser = null;
window.db = null;

// INIT FB CONFIG
var firebaseConfig = {
  apiKey: "AIzaSyD2_79soF8_poJvHW6SDsdxlem1PzuvJnY",
  authDomain: "tkorg-e18c8.firebaseapp.com",
  databaseUrl: "https://tkorg-e18c8.firebaseio.com",
  projectId: "tkorg-e18c8",
  storageBucket: "tkorg-e18c8.appspot.com", //gs colon slash slash tkorg-e18c8.appspot.com slash
  messagingSenderId: "100583444995",
  appId: "1:100583444995:web:42780043470863fb75b436",
  serviceAccount: "firebase-adminsdk-q9hs5@tkorg-e18c8.iam.gserviceaccount.com"
};


firebase.initializeApp(firebaseConfig);
window.db = firebase.firestore();
window.fbstorage = firebase.storage();

/*** Fetch Authorized App Users  ***/
let getUsers = async () => {
  let users_acc = [];
  let outcome_promise = await window.db.collection("users")
    .get()
    .then(
      function (querySnapshot) {
        console.log("...async fetch Users running...");
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          let refObj = doc.data();
          refObj.fid = doc.id;
          console.log(doc.id, " => ", doc.data());
          users_acc.push(refObj);
        });
        return users_acc;
      });
  console.log("...fetched...", users_acc);
  return outcome_promise;
};

 let authorized_users_promised = getUsers();
 console.log("+++++++++++++ authorized_users_promised +++++++++++++++++++");
 authorized_users_promised.then((userlist)=>{
  window.authUsersLive = userlist.map((uo)=>uo.email);
  window.ui = new firebaseui.auth.AuthUI(firebase.auth()); 
 });

//var ui = new firebaseui.auth.AuthUI(firebase.auth()); //.setPersistence(firebase.auth.Auth.Persistence.SESSION)
var uiConfig = {
  autoUpgradeAnonymousUsers: true,
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.

      // 1 save firebase.auth().currentUser to super global
      // console.log("Sign In Success! with authResult: ", authResult);
      let currentUser = firebase.auth().currentUser;
      //let authorizedUsers = ["hdelmanzano@invisioneng.com", "fieldpaymps@gmail.com", "mpsfieldeng@gmail.com", "mpsforeman1@gmail.com", "mpsforeman2@gmail.com", "mpsforeman3@gmail.com", "mpsforeman4@gmail.com", "mpsforeman5@gmail.com", "mpsforeman6@gmail.com", "mpsforeman7@gmail.com", "mpsforeman8@gmail.com", "mpsforeman9@gmail.com", "gandujar@mpsprecast.com", "rmonsanto@mpsprecast.com", "zmorales@mpsprecast.com", "zuheily15@gmail.com", "cncela@gmail.com", "jose.rullan@invisioneng.com", "jose.vazquez@invisioneng.com", "mesmer.social@gmail.com", "mesmerorama@gmail.com", "alexgemelo3@gmail.com", "santanaanibal58@gmail.com"];
      let authorizedUsers = window.authUsersLive;
      //if (currentUser.email != "hdelmanzano@invisioneng.com") {
      if (!authorizedUsers.includes(currentUser.email)) {
        currentUser.delete();
        //...unfortunately stateMachine is not really Responsive yet, so handle pop-up manually here :(
        setTimeout(function () {
          //TODO: improve with simple _unauthorized user_ banner.
          window.ui.start('#firebaseui-auth-container', uiConfig);
        }, 1000);
        return false;
      }

      window.appUser = currentUser;

      // hide spinner I WISH
      document.getElementById('spinner').style.display = 'none';


      return true;
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById('spinner').style.display = 'none';
    },
    signInFailure: function (error) {
      // For merge conflicts, the error.code will be
      // 'firebaseui/anonymous-upgrade-merge-conflict'.
      if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
        return Promise.resolve();
      }
      // The credential the user tried to sign in with.
      var cred = error.credential;
      // Copy data from anonymous user to permanent user and delete anonymous
      // user.
      // ...
      // Finish sign-in after data is copied.
      return firebase.auth().signInWithCredential(cred);
    }
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  signInSuccessUrl: '/resolve',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //'microsoft.com'
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //firebase.auth.GithubAuthProvider.PROVIDER_ID,
    //firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //firebase.auth.PhoneAuthProvider.PROVIDER_ID
  ],
  // Terms of service url.
  tosUrl: '<your-tos-url>',
  // Privacy policy url.
  privacyPolicyUrl: '<your-privacy-policy-url>'
};



const Home = ({ auth_data, reshow_login_func }) => {
  /*** STATEs ***/
  const [messages, setMessages] = useState([]);
  const [tkoState, sendTkostate, tkoService] = useMachine(tkoMachine); //, { devTools: true , context: {appUser: window.appUser}}
  const [geodata, setGeodata] = useState([]);
  const [uiMsg, setUiMsg] = useState("Processing...");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ show_error: false, msg: "" });
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Saved OK!");
  const [failureMsg, setFailureMsg] = useState("Error :(");
  const [fbstatusColor, setFbstatusColor] = useState('warning');
  const [fbuser, setFbuser] = useState({ uid: "init" });

  const [serviceorders, setServiceorders] = useState([]); //deprecated
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});

  const [srIndex, setSrIndex] = useState(0);
  const [formFields, setFormFields] = useState({});
  const [srObj, setSrObj] = useState({});
  const [showSrForm, setShowSrForm] = useState(false);
  let employees = [];
  const [shouldDobuleTake, setShouldDoubleTake] = useState(false);

  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showPunchModal, setShowPunchModal] = useState(false);
  const [showPunchConfirmation, setShowPunchConfirmation] = useState(false);
  const [punchCount, setPunchCount] = useState(0);
  const [punchedToday, setPunchedToday] = useState([]);
  const [picname, setPicname] = useState("");
  const [punchcardNote, setPunchcardNote] = useState("");
  const [tasks, setTasks] = useState([]);
  const [taskcode, setTaskcode] = useState("");
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);


  /*** SIDE EFFECTS ***/
  /***
   * REACT TO XSTATE
   ***/
  useEffect(() => {
    const curr_machine_state = tkoState.value;
    if (curr_machine_state == 'login') {
      console.log("REACTing to LOGIN");
    }
    if (curr_machine_state == 'geotag') {
      console.log("REACTing to GEOTAG");
    }
    if (curr_machine_state == 'select_project') {
      console.log("REACTing to select_project");
      console.log(tkoState.context);
      console.log(tkoState.event);
      if (tkoState.context.geoerror) {
        if (tkoState.context.geoerror.code) {
          if (tkoState.context.geoerror.code == 1) {
            setTimeout(() => {
              sendTkostate({ type: 'KICKOUT', data: tkoState.context.geoerror });
            }, 100);
            setTimeout(function () {
              window.ui.start('#firebaseui-auth-container', uiConfig);
            }, 500);
          }
        }
      }

      fetchProjects();
    }
    if (curr_machine_state == 'punch_employee') {
      console.log("PUNCH_EMPLOYEE BY LOGGED IN USER: ", fbuser);
      console.log("XSTATE PUNCH_EMPLOYEE COMING FROM EVENT: ", tkoState.event);
      console.log("XSTATE PUNCH_EMPLOYEE COMING FROM CONTEXT: ", tkoState.context);
      const selected_project = project;
      let preliminary_picpath = selected_project.locale.replace(/\s+/g, '') + "_" + selected_project.fid;
      let preliminary_picname = tkoState.context.latest_punched_employee.fid + "_" + Date.now().toString() + ".jpg";
      setPicname('images/' + preliminary_picpath + '/' + preliminary_picname);
      setTimeout(() => {
        sendTkostate({ type: 'PUNCH', data: { punchnote: punchcardNote, appuser: fbuser } });
      }, 500);
    }

    if (curr_machine_state == 'punch_confirm') {
      fetchTasks();
    }

    if (curr_machine_state == 'list_employees') {
      setPicname(""); //reset to avoid name overwrites
      console.log("Home transitioning to list_employees View NOW");
      employees = tkoState.context.employees;
      console.log("employees defined as: ", employees);

      if (tkoState.event.type = 'CANCEL') {
        //arrived at list from a cancelled punch_confirm. Delete latest prospective punch on list.
        let overpunched_today = punchedToday;
        const throwaway = overpunched_today.pop();
        setPunchedToday(overpunched_today);
      }


    }

    if (curr_machine_state == 'logout') {
      console.log('Logging Out of React App');
    }


    if (tkoState.context.busy) {
      document.getElementById('spinner').style.display = 'block'; //TODO: use state for showProgress
    } else {
      document.getElementById('spinner').style.display = 'none'
    }
  }, [tkoState]);


  useEffect(() => {
    if (typeof (tkoState.context.latest_punched_employee) !== "undefined") {
      let latest_punched_employee = tkoState.context.latest_punched_employee;
      let all_punched_today = punchedToday;
      all_punched_today.push(latest_punched_employee);
      setPunchedToday(all_punched_today);
    }
  }, [tkoState.context.latest_punched_employee]);


  /*** because we get to EmployeeList after a SYNC doing a cancel on the modal this doesnt get triggered */
  /***
  useEffect(()=>{
    if (tkoState.event.type === 'done.invoke.addPunch') {
      setPunchCount(punchCount + 1);
      setShowPunchConfirmation(true);
    } else {
      setShowPunchConfirmation(false);
    }
  }, [punchedToday]);
  ***/

  useEffect(() => {
    if (typeof (tkoState.context.project) !== "undefined") {
      let sel_project = tkoState.context.project;
      if (project.name !== sel_project.name) {
        setProject(sel_project);
      }
    }
  }, [tkoState.context.project]);


  /*** 
   * SHOW LOGIN POPUP if no auth_data found AND SAVE users auth_data 
   ***/
  useEffect(() => {
    console.log("Starting Login SUPER Flow");
    // Temp variable to hold the anonymous user data if needed.
    var userdata = null;
    // Hold a reference to the anonymous current user.
    var anonymousUser = firebase.auth().currentUser;
    console.log("fbauthcurrentuser: ", anonymousUser);
    console.log("typeof auth_data: ", typeof (auth_data));
    console.log("auth_data", auth_data);

    if (typeof (auth_data) == "undefined" || tkoState.value == "logout") {
      setTimeout(function () {
        if (typeof window.ui != "undefined") {
          window.ui.start('#firebaseui-auth-container', uiConfig);
        } else {
          window.alert("Timeout on authorization data. Please search for a good network connection and try again.");
        }
      }, 2000);

    } else if ((typeof (auth_data.result) != "undefined") && (auth_data.result)) {
      setTimeout(function () {
        //console.log("current LOGGED-IN USER", firebase.auth().currentUser.displayName);
        setFbuser(firebase.auth().currentUser);
        sendTkostate({ type: 'RESOLVE' });
      }, 2000);
    } else {
      console.log("Third way is the way.... idk");
      sendTkostate({ type: 'REJECT' });
    }
  }, [fbuser, setFbuser]);



  let sign_out = () => {
    console.log("Immedately Signing Out...");
    firebase.auth().signOut();

    //cleanup internal states etc then
    auth_data = { result: false };
    setFbuser({ uid: "init" });
    sendTkostate({ type: 'LOGOUT' });
  }

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      var uid = user.uid;
      console.log("Locally, User is considered Signed-In to our Web Service.");
      console.log("Sign-In Successful, for user with UID: " + uid + ".");
    } else {
      // User is signed out
      console.log("User can't be found; Sign-Out successful.");
    }
  });


  /*** FETCHERS ***/
  async function fetchProjects() {
    let projects_acc = [];
    let outcome_promise = await window.db.collection("projects")
      .where("status", "==", "Open")
      .get()
      .then(
        function (querySnapshot) {
          console.log("...async fetch projects running...");
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            let refObj = doc.data();
            refObj.fid = doc.id;
            console.log(doc.id, " => ", doc.data());
            projects_acc.push(refObj);
          });
        });

    setProjects(projects_acc);
    console.log("...fetched...", projects_acc);
    return outcome_promise;
  };

  async function fetchTasks(context) {
    let tasks_acc = [];
    let outcome_promise = await window.db.collection("tasks")
      .get()
      .then(
        function (querySnapshot) {
          console.log("...async fetch tasks running...");
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            let refObj = doc.data();
            refObj.fid = doc.id;
            console.log(doc.id, " => ", doc.data());
            tasks_acc.push(refObj);
          });
        });

    setTasks(tasks_acc);
    console.log("...fetched...", tasks_acc);
    return outcome_promise;
  }


  /*** prep_payload:  Prepare payload object. 
   *                  carbon_copy active serviceorder static fields, merge with live form input fields, trigger geolocation capture.  
   *   Sets State: srObj 
   * ***/
  async function prep_payload() {
    const service_timestamp = Date.now();
    const months = ["Sandman", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let [monthidx, day, year] = new Date(service_timestamp).toLocaleDateString("en-US").split("/");
    console.log("Year Month Day raw: ");
    console.log([monthidx, day, year]);
    const service_date = months[monthidx] + " " + day + ", " + year;
    const active_serviceorder = serviceorders[parseInt(srIndex)];
    let full_srObj = {};

    //... 1 - Carbon Copy all fields from active serviceorder (selected on list) into ServiceReportObject (future payload)
    full_srObj = { ...active_serviceorder };

    //... 2 - Copy all live Data Entry formFields
    full_srObj.service_duration = formFields.service_duration;
    full_srObj.service_materials = formFields.service_materials;
    full_srObj.service_comments = formFields.service_comments;

    //... 3 - Copy Geodata 
    full_srObj.geodata_lat = geodata[0];
    full_srObj.geodata_long = geodata[1];
    full_srObj.geodata_timestamp = geodata[2];

    //... 4 - Status trigger, final tunes
    full_srObj.status = "Submitting";
    full_srObj.service_timestamp = service_timestamp;
    full_srObj.service_date = service_date;
    full_srObj.employee_id = auth_data.auth_data.uidNumber;
    full_srObj.employee_uid = auth_data.auth_data.uid;
    full_srObj.region_id = 1; //TODO, hardcode atm
    full_srObj.region = "Metro";

    //... 4 - Save State
    setSrObj(full_srObj);
  }

  /*** Handler: use native GPS plugin to capture coords and timestamp.
   *   Triggered: by _Save WO_ button through saveWo async func
   *   Output: Save to WOV state. 
   *   Error: if it fails to get GPS coords, the useEffect observing this field will not trigger a remote save. 
   *   Sets State: loading, error
   * ***/
  const getLocation = async () => {
    setUiMsg("Getting Location...");
    setLoading(true);
    try {
      const geoposition = await Geolocation.getCurrentPosition();
      setGeodata([geoposition.coords.latitude, geoposition.coords.longitude, geoposition.timestamp]);
      setLoading(false);
      setError({ showError: false });
    } catch (e) {
      setError({ showError: true, message: e.message });
      setLoading(false);
      console.log("FAILED GPS - NO POSITION AVAILABLE");
      console.log(e);
    }
  }

  async function submitSo() {
    //... 1 getLocation 2 sideEffect detects update to geodata State triggers 
    //... 3 prep_payload 4 sideEffect detects change to Submitting on srObj.status State triggers
    //... 5 save_to_firebase performs .add to collection operation .then 
    //... 6 launches update_fso_to_submitted to set status to Submitted on _serviceorder_ document
    await getLocation();
  }

  /***  ASYNC: continue to prep_payload only if geodata has just been updated (not initialize update) ***/
  useEffect(() => {
    if (geodata.length === 3) {
      if (geodata.timestamp !== "init") {
        console.log("Geodata Changed, triggering prep_payload", geodata);
        prep_payload();
      }
    }
  }, [geodata]);

  /*** ASYNC: continue to SAVE data to firebase if payplod object status is Submitting. */
  useEffect(() => {
    if (srObj.status === "Submitting") {
      console.log("Finally ready to submit: ", srObj);
      save_so_to_firebase();
    }
  }, [srObj]);

  /*** HELPER: actually perform remote save on FB for given obj 
   *   Input: Workorder Object (json) 
   *   Path: (saves to) workorders collection
   *   Sets State: loading
   ****/
  async function save_so_to_firebase() {
    setUiMsg("Saving...");
    setLoading(false);
    setLoading(true);
    let srsObj = srObj;
    window.db.collection("servicereports").add(srsObj)
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
        setLoading(false);
        setShowSrForm(false);
        setSuccess(true);
        update_fso_to_submitted();
        //update_fsr_to_submitted(docRef.id);
        //finally, trigger remote update to Submitted (after Submitting), sideEffect cleans up ServiceOrder open screen
        //finally, cleanup formFields etc
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        setLoading(false);
        setShowSrForm(false);
        setFailureMsg("Error saving your Servicereport: " + error);
        setFailure(true);
      });
  }

  /*** HELPER: remote update the status column on _serviceorder_ collection, updates value to Submitted ***/
  async function update_fso_to_submitted() {
    const active_serviceorder = serviceorders[parseInt(srIndex)];
    const fid = active_serviceorder.fid;
    var active_soRef = window.db.collection("serviceorders").doc(fid);

    return active_soRef.update({
      status: "Submitted"
    })
      .then(function () {
        //fetchServiceOrders();
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        setFailureMsg("Error updating status of the Serviceorder: " + error);
        setFailure(true);

      });
  }

  useIonViewWillEnter(() => {
    sendTkostate({ type: 'LOGIN' });
    //const msgs = getMessages();
    //setMessages(msgs);
  });

  const refresh = (e) => {
    if (tkoState.value == "select_project") {
      fetchProjects();
    }

    if (tkoState.value == "list_employees") {
      //...fetchEmployees()
      console.log("<><><><><><> fetching Employees to update UI");
      let context_project = tkoState.context.project;
      sendTkostate({ type: 'RELOAD', data: { project: context_project } });
    }

    setTimeout(() => {
      forceUpdate();
      e.detail.complete();
    }, 4000);
  };

  //... photos is array of image.jpg objects already captured, most recent first.
  const { photos, takePhoto } = usePhotoGallery();


  useEffect(() => {
    console.log("Photos Array Changed!, see next.");
    console.log(photos);
  }, [photos]);


  const capturePhoto = () => {
    let takePhotoResult = takePhoto();
    //console.log("XState Context LPE as capturePhoto knows it right now: ", tkoState.context.latest_punched_employee);
    //let event_employee = tkoState.context.latest_punched_employee;
    takePhotoResult.then(function (evd) {
      console.log("Success Taking Picture, with data: ", evd);
      sendTkostate({ type: 'CAMDONE', pic: photos[0] });
    }, function (evd) {
      console.log("ERROR taking picture, with data: ", evd);
      let context_project = tkoState.context.project;
      sendTkostate({ type: 'CAMERROR', errorevent: evd, data: { project: context_project } });
    });
  }

  const confirmer = (ev) => {
    console.log("CONFIRM command CLICKED");
    if (taskcode == "" || typeof taskcode == "undefined") {
      document.getElementById("modal-punch-task-code-label").setAttribute("color", "danger");
    } else {
      setShowPunchModal(false);
      sendTkostate({ type: 'CONFIRM', data: { project: project, punchnote: punchcardNote, taskcode: taskcode }, storagepath: picname, picblob: photos[0] });
    }
  }

  return (
    <IonPage id="home-page">
      {tkoState.value !== 'logout' ? (
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start" size="large">
              {tkoState.value !== 'list_employees' ? 'Projects' : 'Employees'}
            </IonTitle>

            {tkoState.value === 'list_employees' ? (
              <IonTitle slot="end" size="small">{tkoState.context.project.name}</IonTitle>
            ) : null}

            <IonButtons slot="end">
              <IonTitle>v0.85</IonTitle>
              <IonMenuButton auto-hide="false" onClick={(e) => { e.persist(); setShowPopover({ showPopover: true, event: e }) }}></IonMenuButton>
            </IonButtons>
          </IonToolbar>
          <IonPopover
            cssClass='user-menu'
            event={popoverState.event}
            isOpen={popoverState.showPopover}
            onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
          >
            <IonHeader>
              <IonToolbar color={fbstatusColor}>
                <IonButtons slot="start">
                  <IonIcon icon={personCircleOutline}></IonIcon>
                </IonButtons>
                <IonTitle>{fbuser == null ? "Signing-In..." : fbuser.displayName}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList>
                <IonItem>
                  <IonIcon icon={keyOutline} slot="start"></IonIcon>
                  <IonButton color="medium" onClick={(ev) => { sign_out(); }}>Sign-Out</IonButton>
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>

        </IonHeader>
      ) : null}


      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonProgressBar type="indeterminate" id="spinner"></IonProgressBar>

        {
          /*** SELECT PROJECT VIEW ***/
          (tkoState.value === "select_project") ? (
            <IonList>
              {projects.map(m => <ProjectListItem key={m.fid} message={m} tkoService={tkoService} sendTkostate={sendTkostate} />)}
            </IonList>
          ) : null
        }

        {
          /*** LIST EMPLOYEES VIEW ***/
          (tkoState.value === "list_employees" || tkoState.value === "punch_confirm") ? (
            <IonList>
              {tkoState.context.employees.map(emp => <EmployeeListItem key={emp.fid} employee={emp} tkoService={tkoService} modalShower={setShowPunchModal} capturePhoto={capturePhoto} />)}
            </IonList>
          ) : null
        }


        {
          /*** PUNCH CONFIRM VIEW ***/
          (tkoState.value === "punch_confirm") ? (
            <IonContent>
              <IonModal isOpen={showPunchModal} cssClass='modal-punch-frame'>
                <IonHeader>Punch Data Confirmation</IonHeader>
                <IonList >
                  <IonItem>
                    <IonLabel className="punch-label-main">
                      <h2 className="candidate">{tkoState.context.latest_punched_employee.first_name + " " + tkoState.context.latest_punched_employee.last_name + "[ E#" + tkoState.context.latest_punched_employee.employee_num + "]"}</h2>
                      <h3>{project.name + " " + new Date(parseInt(tkoState.context.latest_punched_employee.timestamp)).toLocaleTimeString()}</h3>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel id="modal-punch-task-code-label" color="medium">Task Code</IonLabel>
                    <IonSelect value={taskcode} placeholder="Select One" onIonChange={e => setTaskcode(e.detail.value)}>
                      {tasks.map(task => <IonSelectOption value={task.code}>{task.name}</IonSelectOption>)}
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h2>Note</h2>
                    </IonLabel>
                    {/*** value={text} onIonChange={e => setText(e.detail.value!)} ***/}
                    <IonTextarea value={punchcardNote} onIonChange={e => setPunchcardNote(e.detail.value)} placeholder="(Optional)"></IonTextarea>
                  </IonItem>
                  <IonItem>
                    {photos.length > 0 ? (
                      <IonImg className="captured_pic_preview_on_modal" src={photos[0].webviewPath} />
                    ) :
                      <IonLabel>
                        <h2> ( Preview Unavailable )</h2>
                        <h2> ( Error, please try again )</h2>
                      </IonLabel>}
                  </IonItem>
                  <IonItem>
                    <IonGrid>
                      <IonRow className={"sim-align-bottom ion-justify-content-evenly"}>
                        <IonCol></IonCol>
                        <IonCol></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>

                </IonList>
                <IonFooter className="ion-no-border">
                  <IonToolbar>
                    <IonButtons slot="start">

                      <IonButton color="secondary" fill="outline" onClick={() => { setShowPunchModal(false); sendTkostate({ type: 'CANCEL' }) }}>
                        <IonIcon slot="start" icon={trash} />
                        Cancel
                      </IonButton>

                    </IonButtons>
                    <IonButtons slot="end">

                      <IonButton color="tertiary" fill="outline" onClick={confirmer}>
                        <IonIcon slot="end" icon={add} />
                        CONFIRM
                      </IonButton>

                    </IonButtons>
                  </IonToolbar>
                </IonFooter>


              </IonModal>
            </IonContent>

          ) : null
        }


        {(tkoState.value === "logout") ? (
          <IonContent>
            <br /><br />
            <IonCard color="warning">
              <IonCardContent>
                Signed Out Ok!
              </IonCardContent>
            </IonCard>
          </IonContent>
        ) : null}

        <IonToast
          isOpen={true}
          id="hardcoded_toast_confirm"
          onDidDismiss={() => setShowPunchConfirmation(false)}
          message="Employee Time Punched OK"
          color="success"
        />

      </IonContent>

    </IonPage>
  );
};

export default Home;
