async function fetchProjects() {
  let projects_acc = [];    
  let outcome_promise = await window.db.collection("projects")
          .where("status", "==", "Done")
          .get()
          .then(
            function(querySnapshot) {
              console.log("...async FETCH EMPLOYEES running...");
              querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                let refObj = doc.data();
                refObj.fid = doc.id;
                console.log(doc.id, " => ", doc.data());
                projects_acc.push(refObj);
              });
  });

  //setProjects(projects_acc);
  console.log("...fetched...", projects_acc);
  return outcome_promise;
};

export interface Message {
  fromName: string;
  subject: string;
  date: string;
  id: number;
}

const messages: Message[] = [
  {
    fromName: 'Matt Chorsey',
    subject: 'New event: Trip to Vegas',
    date: '9:32 AM',
    id: 0
  },
  {
    fromName: 'Lauren Ruthford',
    subject: 'Long time no chat',
    date: '6:12 AM',
    id: 1
  },
  {
    fromName: 'Jordan Firth',
    subject: 'Report Results',
    date: '4:55 AM',
    id: 2

  },
  {
    fromName: 'Bill Thomas',
    subject: 'The situation',
    date: 'Yesterday',
    id: 3
  },
  {
    fromName: 'Joanne Pollan',
    subject: 'Updated invitation: Swim lessons',
    date: 'Yesterday',
    id: 4
  },
  {
    fromName: 'Andrea Cornerston',
    subject: 'Last minute ask',
    date: 'Yesterday',
    id: 5
  },
  {
    fromName: 'Moe Chamont',
    subject: 'Family Calendar - Version 1',
    date: 'Last Week',
    id: 6
  },
  {
    fromName: 'Kelly Richardson',
    subject: 'Placeholder Headhots',
    date: 'Last Week',
    id: 7
  }
];

export const getMessages = () => {
    fetchProjects();
  return messages;
}

export const getMessage = (id: number) => messages.find(m => m.id === id);
