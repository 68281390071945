import {
    IonItem,
    IonLabel,
    IonNote,
    IonIcon
    } from '@ionic/react';
  import { Message } from '../data/messages';
  import './MessageListItem.css';

  import { useService } from '@xstate/react';

  import { business, businessOutline, fingerPrint }  from 'ionicons/icons';
  
  interface MessageListItemProps {
    message: Message;
    tkoService: tkoService;
  }
  
 
 

  const ProjectListItem: React.FC<MessageListItemProps> = ({ message, tkoService }) => {
    
    const [tkoState, sendState] = useService(tkoService);
   
    const stepNext = () => {
      // sendTkostate({type: 'SELECTED'});
      console.log("STEPPING TO LIST_EMPLOYEES NOW"); 
      sendState({type: 'SELECTED', data: {project: message}});
      
      //console.log("My Machine Service official state is now: ", tkoState)
     }
    
     /***  IonItem Old Router way was to include routerLink={`/message/${message.fid}`} ***/

    return (
      <IonItem detail={false} onClick={stepNext}>
        <div slot="start" className="dot"><IonIcon icon={fingerPrint}></IonIcon></div>
        <IonLabel className="ion-text-wrap">
          <h2>{message.name}</h2>
          <h3>{message.locale}</h3>
        </IonLabel>
      </IonItem>
    );
  };
  
  export default ProjectListItem;
  