import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonModal, IonList, IonListHeader, IonItemDivider, IonItem, IonInput, IonButton, IonLoading, IonCard, IonCardContent } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cloudDoneOutline, fileTrayOutline, images, bandage } from 'ionicons/icons';
import Home from './pages/Home';

import EmployeeList from './pages/EmployeeList';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => {
  /***  State ***/
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [userEntry, setUserEntry] = useState("");
  const [passwEntry, setPasswEntry] = useState("");
  const [authObj, setAuthObj] = useState({
    auth_status: false,
    auth_data: { lde_message: "init" },
    token: -1,
    auth_group: 'init'
  });


  /*** Effect - UIUX Respond to Sign-in attempts ***/
  useEffect(
    () => {
      let feedback_msg = "Unexpected Error, please try again.";
      setShowSpinner(false);
      setShowFeedback(false);
      console.log("Sign-in attempted, update effect executing.");
      if (authObj.auth_status) {
        //setShowLoginModal(false);  //EXIT, dismiss Sign-In modal after success
      } else {
        //setShowLoginModal(true);
        feedback_msg = (typeof authObj.auth_data == "string") ? authObj.auth_data : authObj.auth_data.lde_message;
        setFeedback(feedback_msg);
        if (feedback_msg !== "init") {
          setShowFeedback(true); //...avoid first load state update show
        }
      }
    },
    [authObj],
  );

  /*** Effect - Load AuthObj Appstate and UIUX Hide Sign-In modal if valid authObj found in localStorage 
   *            Checks ONCE upon first render
  */

  useEffect(() => {
    let valid_persisted_auth_str = localStorage.getItem('authObj');
    if (valid_persisted_auth_str) {
      let valid_persisted_auth_obj = JSON.parse(valid_persisted_auth_str);
      setAuthObj(valid_persisted_auth_obj);
      if (valid_persisted_auth_obj.auth_status) {
        setShowLoginModal(false);
      } else {
        setShowLoginModal(true);
      }
    }
  }, []);


  /*** POST to API handler ***/
  /*** for Invision to d-a-a-s jc ldap to jwt workflows only */
  return (
    <IonApp>
      <IonModal isOpen={showLoginModal}>
        {
          showFeedback ?
            <IonCard color={"danger"}>
              <IonCardContent>
                <IonIcon icon={bandage}></IonIcon>
                {" " + feedback}
              </IonCardContent>
            </IonCard>
            : null
        }
        <IonList>
          <IonListHeader>
            <IonLabel>Sign-in to work with Ponchapp</IonLabel>
          </IonListHeader>
        </IonList>

        <IonLoading
          isOpen={showSpinner}
          onDidDismiss={() => setShowSpinner(false)}
          message={'Authenticating...'}
        />
      </IonModal>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/" exact={true}>
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact={true}>
            <Home />
          </Route>
          <Route path="/resolve" exact={true}>
            <Home auth_data={{ result: true }} />
          </Route>

          <Route path="/message/:id">
            <EmployeeList />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
